import Vue from 'vue';
import VueRouter from 'vue-router';
import apolloProvider from '@/apollo';
import { getToken, onLogout } from '@/vue-apollo';
import store from '@/store/index';
import { i18n } from '../i18n';


const apollo = apolloProvider.defaultClient;

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Login.vue'),
    meta: {
      forVisitors: true,
    },
    alias: '/login',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/IndexView.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.dashboard'),
      },
    },
    children: [
      {
        path: '',
        name: 'dashboard-conversations',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/ConversationsView.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      },
      {
        path: 'agents',
        name: 'dashboard-agents',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/AgentsView.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      },
      {
        path: 'messages',
        name: 'dashboard-messages',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/MessagesView.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      },
      {
        path: 'bot',
        name: 'conversations-ivr',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/BotView.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      },
      {
        path: 'summary',
        name: 'conversations-summary',
        component: () => import(/* webpackChunkName: "dashboard.summary" */ '../views/Dashboard/SummaryView.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      },
    ],
  },
  {
    path: '/send-templates',
    name: 'templates',
    component: () => import(/* webpackChunkName: "templates" */ '../views/SendTemplates.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('templates.title'),
      },
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '../views/Notification.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.notifications'),
      },
    },
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: () => import(/* webpackChunkName: "campaigns" */ '../views/Campaigns.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.campaigns'),
      },
    },
  },
  {
    path: '/campaigns/:campaign_id/settings',
    name: 'campaigns-settings',
    component: () => import(/* webpackChunkName: "dashboard/settings" */ '../views/CampaignsSettings.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.campaign-settings'),
      },
    },
  },
  {
    path: '/campaigns/:campaign_id/channels-settings',
    name: i18n.t('campaigns.cards.channels-settings'),
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/CampaignChannelsSettings/CampaignChannelsSettings.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('campaigns.cards.channels-settings'),
      },
    },
  },
  {
    path: '/campaigns/:campaign_id/assign-users',
    name: 'campaigns-assign-users',
    component: () => import(/* webpackChunkName: "campaigns/assign-users" */ '../views/CampaignsAssignUsers.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.assign-user'),
      },
    },
  },
  {
    path: '/campaigns/:campaign_id/blocked-clients',
    name: 'campaigns-blocked-clients',
    component: () => import(/* webpackChunkName: "campaigns/blocked-clients" */ '../views/CampaignsBlockedClients.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.blocked-clients'),
      },
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.users'),
      },
    },
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import(/* webpackChunkName: "messages" */ '../views/Messages.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.messages'),
      },
    },
  },
  {
    path: '/team',
    name: 'team',
    component: () => import(/* webpackChunkName: "team" */ '../views/Team.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.team'),
      },
    },
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => import(/* webpackChunkName: "configuration" */ '../views/Configuration.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.configuration'),
      },
    },
  },
  {
    path: '/company',
    name: 'company',
    component: () => import(/* webpackChunkName: "company" */ '../views/Companies.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.company'),
      },
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import(/* webpackChunkName "reports" */ '../views/Reports.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.reports'),
      },
    },
  },

  {
    path: '/estudios',
    name: 'estudios',
    component: () => import(/* webpackChunkName "estudios" */ '../views/Estudios.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.estudios'),
      },
    },
  },

  {
    path: '/microconsultas',
    name: 'microconsultas',
    component: () => import(/* webpackChunkName "Microconsultas" */ '../views/Microconsultas.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.microconsultas'),
      },
    },
  },

  {
    path: '/historialclinico',
    name: 'historialclinico',
    component: () => import(/* webpackChunkName "historialclinico" */ '../views/HistorialClinico.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.historialclinico'),
      },
    },
  },

  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/PageNotFound.vue'),
    meta: {
      forAuth: false,
      layout: 'without-layout',
      site: {
        name: '404',
      },
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'login' && to.query.data) {
    await onLogout(apollo);
  }

  const isAuthenticated = store.getters['dashboard/getAccessToken'];
  const token = !!getToken();

  if (to.matched.some(record => record.meta.forVisitors) && isAuthenticated && token) {
    next('/dashboard');
  }
  if (to.matched.some(record => record.meta.forAuth) && (!isAuthenticated || !token)) {
    next('/');
  }

  if (to.name === 'users' || to.name === 'company') {
    const user = store.getters['shared/userInfo'];
    if (user.role !== 'owner' && to.name === 'company') {
      next('*');
    }
    if (user.role !== 'owner' && user.role !== 'super') {
      next('*');
    }
  }

  next();
});

export default router;
