const chartColors = [
  '#F56565',
  '#ED8936',
  '#ECC94B',
  '#4299E1',
  '#48BB78',
  '#38B2AC',
  '#667EEA',
  '#9F7AEA',
  '#FC8181',
  '#F6AD55',
  '#F6E05E',
  '#68D391',
  '#4FD1C5',
  '#63B3ED',
  '#7F9CF5',
  '#B794F4',
  '#FEB2B2',
  '#FBD38D',
  '#FAF089',
  '#9AE6B4',
  '#81E6D9',
  '#90CDF4',
  '#A3BFFA',
  '#D6BCFA',
  '#FED7D7',
  '#FEEBC8',
  '#FEFCBF',
  '#C6F6D5',
  '#B2F5EA',
  '#BEE3F8',
  '#C3DAFE',
  '#E9D8FD',
  '#00A5e3',
  '#00B0BA',
  '#0065A2',
  '#00CDAC',
  '#FF96C5',
  '#FF5768',
  '#FF828B',
  '#FF60A8',
  '#FF5C77',
  '#E77577',
  '#FC6238',
  '#FFBF65',
  '#C05780',

];

function getDisplayableFormat(format) {
  switch (format) {
    case 'YYYY-MM-DD':
      return 'DD MMM YYYY';
    case 'YYYY-MM':
      return 'MMM YYYY';
    case 'YYYY':
      return 'YYYY';
    case 'HH':
      return 'hh:mm A';
    case 'E':
      return 'ddd';
    case 'MM':
      return 'MMM';
    default:
      return format;
  }
}

function clearString(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function compareTo(str1, str2) {
  const comp1 = clearString(str1).toLowerCase();
  const comp2 = clearString(str2).toLowerCase();
  return comp1 === comp2;
}

function getDataNotification(item) {
  const data = Object.assign({}, item);
  let msg = '';
  let title = '';
  if (item.action.type === 'show-detail' && item.type !== 'template-status') {
    msg = `Numeros enviados: ${item.message}`;
    title = 'template';
    data.message = msg;
  } else if (item.type === 'template-status') {
    msg = item.message;
    title = 'template-status';
    data.message = msg;
  } else {
    title = 'report';
  }

  data.title = title;
  return data;
}

function validPassword(password) {
  const isValid = new RegExp('^(?=.*?[0-9])(?=.*?[A-Z])(?=.*[a-z]).{8,}$');
  return isValid.test(password);
}

function hours() {
  const time = [];
  for (let i = 1; i < 24; i += 1) {
    time.push({ id: `${i}`, name: `${i}:00` });
  }
  time.push({ id: '0', name: '24:00' });
  return time;
}

export {
  hours,
  getDisplayableFormat,
  chartColors,
  getDataNotification,
  validPassword,
  clearString,
  compareTo,
};
