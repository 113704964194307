<template>
  <div class="off-canvas-wrapper" :class="{ scrollNone: activeNotification }">
    <site-header v-on:open-notifications="openNotifications" />
    <div
      data-off-canvas
      id="offCanvas"
      class="off-canvas position-left reveal-for-medium"
      v-bind:class="{ 'menu-collapse': collapse, overflow: collapse }"
    >
      <div class="site-logo">
        <div class="logo" v-on:click="collapse = !collapse"></div>
      </div>
      <div class="sidebar-wrapper">
        <nav class="sidebar-menu" data-close="offCanvas">
          <ul>
            <li v-for="(item, index) in items()" v-bind:key="index">
              <router-link v-bind:to="item.href" :key="item.title">
                <div class="icon">
                  <component v-bind:is="item.icon" />
                </div>
                <div class="sidebar-link">
                  <span class="title">{{ item.title }}</span>
                </div>
              </router-link>
            </li>
            <li>
              <a :href="addOnsUrl" target="_blank">
                <div class="icon">
                  <puzzle-icon />
                </div>

                <div class="sidebar-link">
                  <span class="title">{{ $t('app.add-ons') }}</span>
                </div>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div
      data-off-canvas-content
      class="main-content grid-container full"
      v-bind:class="{ 'canvas-collapse': collapse }"
    >
      <div class="grid-x">
        <div class="cell">
          <transition name="component-fade" mode="out-in">
            <keep-alive>
              <slot />
            </keep-alive>
          </transition>
        </div>
      </div>
    </div>
    <menu-notification v-if="!isLogin" ref="notificationsMenu" />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import jwtDecode from 'jwt-decode';
import errorHandler from '@/utils/error.handler';

export default {
  components: {
    SiteHeader: () => import('@/components/Header.vue'),
    MenuNotification: () => import('@/components/MenuNotification.vue'),
  },
  data() {
    return {
      currentCompany: null,
    };
  },
  computed: {
    ...mapGetters({
      accessToken: 'dashboard/getAccessToken',
      commonCompany: 'shared/getCurrentCompany',
    }),
    role() {
      try {
        const decoded = jwtDecode(this.accessToken);
        return decoded.role ? decoded.role : '';
      } catch (e) {
        errorHandler.logErrors(e);
        return '';
      }
    },
    collapse: {
      get() {
        return this.$store.state.dashboard.collapse;
      },
      set(value) {
        this.toogleCollapse(value);
      },
    },
    isLogin() {
      return this.$route.name === 'login';
    },
    isOwner() {
      return this.role === 'owner';
    },
    isSuper() {
      return ['super', 'owner', 'admin'].includes(this.role);
    },
    isSandbox() {
      if (this.currentCompany) {
        return ['606bc87ac0db820c2b843c6f', '62990ded85598b07cd00c3a2', '627403c0657978e24c67035a']
          .includes(this.currentCompany);
      }
      return false;
    },
    isProd() {
      return process.env.VUE_APP_BOT_BUILDER === 'https://addons-sellia.web.app';
    },
    activeNotification() {
      return this.$store.state.notification.toogleMenu;
    },
    messagesUrl() {
      return `${process.env.VUE_APP_REDIRECT_URL}/conversations`;
    },
    teamUrl() {
      return `${process.env.VUE_APP_REDIRECT_URL}/team`;
    },
    dataBankUrl() {
      return `${process.env.VUE_APP_REDIRECT_URL}/data-bank`;
    },
    addOnsUrl() {
      return (process.env.VUE_APP_BOT_BUILDER || '')
        .replace('{{TOKEN}}', this.accessToken)
        .replace('{{COMPANY}}', this.currentCompany);
    },
  },
  watch: {
    commonCompany(newVal) {
      this.currentCompany = newVal.id;
    },
  },
  methods: {
    ...mapMutations({
      toogleCollapse: 'dashboard/toogleCollapse',
    }),
    openNotifications() {
      this.$refs.notificationsMenu.displayMenu();
    },
    items() {
      const itemsTotal = [
        {
          title: this.$t('app.dashboard'),
          href: '/dashboard',
          icon: 'view-dashboard-icon',
          new: false,
        },
        {
          title: this.$t('app.send-templates'),
          href: '/send-templates',
          icon: 'whatsapp',
          new: false,
        },
        {
          title: this.$t('app.doctors'),
          href: '/campaigns',
          icon: 'settings-icon',
          new: true,
        },
        {
          title: this.$t('app.messages'),
          href: '/messages',
          icon: 'message-icon',
          new: true,
        },

        {
          title: this.$t('app.estudios'),
          href: '/estudios',
          icon: 'file-document-icon',
          new: true,
        },

        {
          title: this.$t('app.microconsultas'),
          href: '/microconsultas',
          icon: 'eye-icon',
          new: true,
        },

        {
          title: this.$t('app.historialclinico'),
          href: '/historialclinico',
          icon: 'history-icon',
          new: true,
        },
      ];

      if (this.role === 'owner' || this.role === 'super') {
        itemsTotal.push({
          title: this.$t('app.users'),
          href: '/users',
          icon: 'account-multiple-icon',
          new: true,

        });
      }
      if (this.role === 'owner') {
        itemsTotal.push({
          title: this.$t('app.company'),
          href: '/company',
          icon: 'briefcase-icon',
          new: true,
        });
      }
      return itemsTotal;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_site-navigation.scss";
</style>
