import Vue from 'vue';
import numeral from 'numeral';
import * as VueGoogleMaps from 'vue2-google-maps';
import numFormat from 'vue-filter-number-format';
import Tooltip from 'vue-directive-tooltip';
import VueSweetalert2 from 'vue-sweetalert2';
import VueFileAgent from 'vue-file-agent';
import VueClipboard from 'vue-clipboard2';
import Toasted from 'vue-toasted';
import VModal from 'vue-js-modal';
import Vuelidate from 'vuelidate';
import * as TgsPlayer from '@lottiefiles/lottie-player/dist/tgs-player';
import * as firebase from 'firebase/app';
import firebaseConfig from '@/firebase-config';
import App from './App.vue';
import apolloProvider from './apollo';
import router from './router';
import store from './store';
import { i18n } from './i18n';
import './icons';
import 'firebase/firestore';
import 'firebase/analytics';
import mixins from './mixins';
import SiteNavigation from './layouts/SiteNavigation.vue';
import NotSideBar from './layouts/NotSideBar.vue';
import { filesize, truncate } from './utils/filters';


Vue.filter('numFormat', numFormat(numeral));
Vue.use(Tooltip);
Vue.use(VueSweetalert2);
Vue.use(VueClipboard);
Vue.use(TgsPlayer);

// Modals
Vue.use(VModal, { dialog: true });

// Toast Alerts
Vue.use(Toasted);

// Google Maps
const GOOGLE_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_API_KEY,
    libraries: 'places',
  },
});

const toastOptions = {
  duration: 3000,
  keepOnHover: true,
};

Vue.toasted.register('info', payload => payload, {
  type: 'info', icon: 'info', ...toastOptions,
});
Vue.toasted.register('success', payload => payload, {
  type: 'success', icon: 'check_circle', ...toastOptions,
});
Vue.toasted.register('error', payload => payload, {
  type: 'error', icon: 'cancel', ...toastOptions,
});

firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.config.productionTip = process.env.VUE_APP_PRODUCTIONTIP || false;

// Vue.config.errorHandler = (error, vm, info) => {
//   console.log(error, vm, info);
// };

Vue.prototype.$analytics = firebase.analytics();

Vue.mixin(mixins);
Vue.filter('filesize', filesize);
Vue.filter('truncate', truncate);

// components
Vue.use(VueFileAgent);
Vue.use(Vuelidate);
Vue.component('navigation-layout', SiteNavigation);
Vue.component('without-layout', NotSideBar);

new Vue({
  router,
  store,
  i18n,
  apolloProvider,
  render: h => h(App),
}).$mount('#app');
