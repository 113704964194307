<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <chat-button />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ChatButton from '@/components/chatButton/index.vue';

const defaultLayout = 'without-layout';

export default {
  components: {
    ChatButton,
  },
  computed: {
    ...mapGetters({
      userInfo: 'shared/userInfo',
      userRole: 'shared/getUserRole',
    }),
    layout() {
      return (this.$route.meta.layout || defaultLayout);
    },
    showChatButton() {
      return this.userRole === 'super' || this.userRole === 'admin';
    },
  },
  created() {
    if (this.userInfo) {
      this.setIdentityLogRocket();
    }
  },
  watch: {
    userInfo(newState) {
      if (newState) {
        this.setIdentityLogRocket();
      }
    },
  },
  methods: {
    setIdentityLogRocket() {
      this.$analytics.setUserId(this.userInfo.id);
      this.$analytics.setUserProperties({
        account_type: this.userRole,
      });
    },
  },
};
</script>

<style lang="scss">
@import "~styles/_app.scss";
</style>
