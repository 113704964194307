<template>
  <div class="select-container">
    <label
      v-if="label"
      class="select__label"
      :class="requiredInput && 'select__label-required'"
      >{{ label }}</label
    >
    <multiselect
      v-bind="$attrs"
      v-on="$listeners"
      :label="optionLabel"
      :show-labels="showLabels"
      :searchable="searchable"
      :allow-empty="allowEmpty"
      :deselect-label="deselectLabel"
      :disabled="disabled"
      :class="[customClass, { error }, { disabled }]"
      @close="toggle"
      @open="toggle"
      ref="multiselect"
    >
      <template slot="option" slot-scope="props" v-if="props.option.icon">
        <component
          class="select__icon"
          :is="props.option.icon"
          :class="props.option.color"
        />
        <span class="option__title">{{ props.option.title }}</span>
      </template>
      <template
        slot="information"
        slot-scope="props"
        v-if="props.option.information"
      >
        <span class="option__title">{{ props.option.name }} : </span>
        <span>{{ props.option.information }}</span>
      </template>
      <template slot="selection" slot-scope="{ values, isOpen }">
        <span class="multiselect__single" v-if="values.length > 2 && !isOpen">
          {{ values.length }} {{ selectedText }}
        </span>
        <span v-else-if="isOpen"></span>
      </template>
      <template slot="noResult">{{ $t("multiselect.no-result") }}</template>
      <template slot="noOptions">{{ $t("multiselect.no-options") }}</template>
      <slot />
    </multiselect>
    <div v-if="error && errorMessage" class="select__error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  props: {
    showLabels: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    optionLabel: {
      type: String,
      required: true,
    },
    deselectLabel: {
      type: String,
    },
    label: String,
    customClass: {
      type: [String, Object, Array],
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedText: {
      type: String,
      default: 'Opciones seleccionadas',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      if (this.isOpen) {
        this.$refs.multiselect.$el.blur();
        this.isOpen = false;
      } else {
        this.$refs.multiselect.$el.focus();
        this.isOpen = true;
      }
    },
  },
};
</script>
