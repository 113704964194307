/* eslint-disable import/prefer-default-export */
import Vue from 'vue';
import userActions from './actions/userActions';
import fetchCompanies from './actions/shared';

export const shared = {
  namespaced: true,
  state: {
    user: {},
    currentCompanyID: null,
    companies: [],
    lang: null,
    logged: true,
    roles: [],
  },
  getters: {
    userInfo(state) {
      return state.user;
    },
    getCurrentCompany(state) {
      return state.companies.find(({ id }) => id === state.currentCompanyID) || null;
    },
    getCompanies(state) {
      return state.companies;
    },
    getFilterCompanies(state) {
      return state.companies.filter(({ maxUsers }) => maxUsers > 0);
    },
    isOwner(state) {
      return state.user.role === 'owner';
    },
    getLocale(state) {
      return state.lang;
    },
    isLogged(state) {
      return state.logged;
    },
    getUserRole(state) {
      return state.user.role;
    },
    getRoles(state) {
      return state.roles;
    },
    getLanguage(state) {
      return state.lang;
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setCompanies(state, payload) {
      state.companies = payload;
    },
    setCurrentCompanyID(state, payload) {
      state.currentCompanyID = payload;
    },
    setLang(state, lang) {
      state.lang = lang;
    },
    setLogged(state, logged) {
      state.logged = logged;
    },
    logout(state) {
      state.companies = [];
    },
    setRoles(state, data) {
      state.roles = data;
    },
    updateCampaignCompany(state, newData) {
      const index = state.companies.findIndex(({ id }) => id === newData.id);
      if (index !== -1) {
        const company = state.companies[index];
        const i = company.campaigns
          .findIndex(({ id }) => id === newData.idCampaing);

        const updated = {
          ...company.campaigns[i],
          users: newData.users,
        };

        Vue.set(company.campaigns, i, updated);
        Vue.set(state.companies, index, company);
      }
    },
  },
  actions: {
    ...userActions,
    ...fetchCompanies,
  },
};
