import fullCompaniesGql from '@/graphql/queries/fullCompanies.gql';
import executeQuery from '@/utils/gql-api';
import errorHandler from '@/utils/error.handler';

export default {
  fetchCompanies({ commit }) {
    executeQuery(
      'fullCompanies',
      fullCompaniesGql,
      {},
      false,
    ).then((result) => {
      commit('setCompanies', result);
    })
      .catch((err) => {
        errorHandler.logErrors(err);
      });
  },
};
