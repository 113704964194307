<template>
  <loading
    :loader="type"
    :active.sync="isLoading"
    :can-cancel="false"
    :is-full-page="false">
    <template #before>
      <h3>{{ $t('app.loading') }}</h3>
    </template>
  </loading>
</template>

<script>
import Loading from 'vue-loading-overlay';

export default {
  props: {
    loading: {
      require: true,
      type: Boolean,
    },
    type: {
      type: String,
      default: 'bars',
    },
  },
  components: {
    Loading,
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
};

</script>

<style scoped lang="scss">
  @import '~vue-loading-overlay/dist/vue-loading.css';

  .vld-overlay {
    outline: none;
  }

  ::v-deep .vld-icon {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
</style>
