import byCampaign from '@/graphql/queries/campaigns/dataBank/byCampaign.gql';
import listTemplatesGql from '@/graphql/queries/listTemplates.gql';
import executeQuery from '@/utils/gql-api';
import errorHandler from '@/utils/error.handler';

export default {
  fetchDataBank({ commit }, { id, type }) {
    executeQuery('dataBank',
      byCampaign,
      {
        id,
        type,
      })
      .then((payload) => {
        commit('setDataBank', { type, payload });
      })
      .catch((err) => {
        commit('setDataBank', { type, payload: [] });
        errorHandler.logErrors(err);
      });
  },
  fetchTemplates({ commit }, id) {
    if (id != null) {
      executeQuery('listTemplates',
        listTemplatesGql,
        {
          campaign_id: id,
        })
        .then((payload) => {
          commit('setTemplates', payload);
        })
        .catch(err => errorHandler.logErrors(err));
    }
  },
  updateDataBank({ commit, getters }, res) {
    const dataBank = getters.getDataBank;
    const index = dataBank[res.type].findIndex(d => d.id === res.id);
    commit('updateDataBank', { payload: res, index });
  },
  addDataBank({ commit, getters }, payload) {
    const dataBank = getters.getTabSection(payload.type);
    dataBank.push(payload);
    commit('setDataBank', { type: payload.type, payload: dataBank });
  },
  deleteData({ commit, getters }, { res, type }) {
    const dataBank = getters.getDataBank;
    const index = dataBank[type].findIndex(d => d.id === res.id);
    commit('deleteDataBank', { index, type });
  },
  deleteTemplate({ commit, getters }, { id }) {
    const templates = getters.getTemplates;
    const filterDeleted = templates.filter(templ => templ.id !== id);
    commit('setTemplates', filterDeleted);
  },
  addTemplate({ commit }, payload) {
    commit('addTemplate', payload);
  },
  clearDatabank({ commit }) {
    commit('clear');
  },
};
